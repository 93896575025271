/* JS - (c) 2019 mpunkt GmbH */
import $ from 'jquery';

// jQuery Definitionen
window.jQuery = $;
window.$ = $;

require('../vendor/modernizr.touch.js');

// Scripts asynchron laden
(function ($) {
    $.extend({
        loadScript: function (source, callback, cache) {
            var c = (typeof cache == 'undefined' ? true : cache);
            (function (d, t) {
                var g = d.createElement(t),
                    s = d.getElementsByTagName(t)[0];
                g.src = source + (c == false ? '?_=' + new Date().getTime() : '');
                g.onload = function () {
                    callback();
                };
                s.parentNode.insertBefore(g, s);
            }(document, 'script'));
        }
    });
})($);

$(document).scroll(function () {
    // Scroll-Top Button erst ab bestimmter Scroll-Tiefe anzeigen
    var scrollY = $(this).scrollTop();
    if (scrollY > 200) {
        $('#topBtn').css({
            opacity: 0.7,
            pointerEvents: 'auto'
        });
    } else {
        $('#topBtn').css({
            opacity: 0,
            pointerEvents: 'none'
        });
    }
});

$(document).ready(function () {
    // CSS-Klasse wechseln, wenn JS aktiviert ist (für Fallbacks)
    $('html:first').removeClass('no-js').addClass('js');
    $('html').addClass('docready');

    // Script-Pfad herausfinden, in welchem sich jQuery befindet
    // Die muss dynamisch erfolgen, da man durch die Mehrsprachigkeit sich eine Ebene tiefer befinden kann!
    const JsPath = $('script[src*=\'bundle\']').first().attr('src').split('?')[0].split('/').slice(0, -1).join('/') + '/';

    // Content Rahmen ums Menü
    $('.menu').wrap('<div class="content-limiter"></div>');

    // Menu Mobile Hamburger Button - Klasse auf Body on Click
    $('.menu-btn-mobile').on('click', function () {
        $('body').toggleClass('menu-is-open');
    });

    // Wrap im Footer Social Media Buttons und Links
    // $('#footer .widget .widget-area > li:nth-child(4), #footer .widget .widget-area > li:nth-child(5)').wrap('<div class="footer-social-icons"></div>');

    //Scroll to top Button
    const topBtn = $('#topBtn');
    if (topBtn.length) {

        // Erst nach bestimmter Scroll-Tiefe anzeigen
        if ($(window).scrollTop() < 200) {
            topBtn.css({
                opacity: 0,
                pointerEvents: 'none'
            });
        }

        var scrollTrigger = 100, // px
            backToTop = function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scrollTrigger) {
                    topBtn.addClass('show');
                } else {
                    topBtn.removeClass('show');
                }
            };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        topBtn.on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }

    // Klappboxen FAQ und Team
    $('.klappbox .widget-title').click(function () {
        $(this).next('div').slideToggle('fast');
        $(this).toggleClass('open-klappbox').children('p').stop().slideToggle(200);
    });

    // Counter Animation für Zahlen bei Standort
    $('.count').each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
    // Wrap im Texteditor allgemein für Links
    $('.textbox .widget > *:not(.rote-info-textbox) .textwidget a').each(function () {
        $(this).addClass('text-link').wrap('<span class="highlight"></span>');
    });
    $('.widget .klappbox > *:not(.rote-info-textbox) .textwidget a').each(function () {
        $(this).addClass('text-link').wrap('<span class="highlight"></span>');
    });

    // Ausklappen des Submenü
    $('li.menu-item-has-children').each(function () {
        $(this).append('<span class="sub-open"></span>');
    });
    $('.sub-open').on('click', function () {
        $(this).prevAll('.sub-menu').toggleClass('open');
    });

});

